import React, { useState } from "react";
import { Container, Col, Row, Form, Button, Alert } from "react-bootstrap";
import data from "../data/data.json";
import axios from "axios";

const Php = () => {
  const selectedLink = data.api.find((link) => link.route === "/php");

  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();

    const api = axios.create({
      baseURL: "https://api-login-php.davidebalice.dev",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    });

    try {
      const requestBody = {
        username: username,
        password: password,
      };
      const response = await api.post("/api/login", requestBody);
      setResponse(response.data);
      setError(null);
    } catch (error) {
      setError("Error during API request: " + error);
      setResponse(null);
    }
  };

  return (
    <Container className="mt-5">
      <div>{selectedLink.name}</div>
      <div className="mb-5">{selectedLink.description}</div>
      <Row>
        <Col md={6}>
          <p className="font-weight-bold">Request</p>
          <Form.Select custom>
            <option>GET Login</option>
          </Form.Select>

          <Form onSubmit={handleSubmit} className="mt-5">
            <Form.Group controlId="username">
              <Form.Label>Username</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter your username"
                value={username}
                required
                onChange={(e) => setUsername(e.target.value)}
              />
            </Form.Group>

            <Form.Group controlId="password">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Enter your password"
                value={password}
                required
                onChange={(e) => setPassword(e.target.value)}
              />
            </Form.Group>

            <Button variant="primary" type="submit" className="mt-3">
              Login
            </Button>
          </Form>
        </Col>
        <Col md={6}>
          <p className="font-weight-bold mb-5">Response</p>{" "}
          {response && (
            <div className="mt-3">
              <Alert variant="success">
                Risposta API: {JSON.stringify(response)}
              </Alert>
            </div>
          )}
          {error && (
            <div className="mt-3">
              <Alert variant="danger">Error: {error}</Alert>
            </div>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default Php;
